import http from "../http-common";

class UploadFilesService {
  upload(file, fileType, orderId, onUploadProgress) {
    let formData = new FormData();

    formData.append("orderId", orderId);
    formData.append("fileType", fileType);
    formData.append("file", file);

    return http.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  getFiles(orderId) {
    return http.get("/api/files/" + orderId);
  }
}

export default new UploadFilesService();
