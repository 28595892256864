<template>
  <v-data-table
    :headers="headers"
    :items="orders"
    :search="search"
    :options.sync="options"
    :loading="loading"
    sort-by="order_book_date"
    class="elevation-1"
    item-key="order_id"
    show-select
    :footer-props="{
      showFirstLastPage: true
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>订单管理</v-toolbar-title>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="查找"
          single-line
          hide-details
        ></v-text-field>
        <v-divider inset vertical class="mx-4"></v-divider>
        <v-dialog v-model="dialogEdit" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle dense>
              <v-btn class="mb-2" v-bind="attrs" v-on="on">
                <v-icon color="green">mdi-file-outline</v-icon>
                新建
              </v-btn>
              <v-btn class="mb-2">
                <v-icon color="orange">mdi-delete</v-icon>
                删除
              </v-btn>
              <v-btn class="mb-2">
                <v-icon color="blue">mdi-file-export-outline</v-icon>
                导出
              </v-btn>
            </v-btn-toggle>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.ordernum"
                      label="订单号"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.customer_ordernum"
                      label="客户订单号"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="customers"
                      item-text="short_name"
                      item-value="customer_id"
                      v-model="editedItem.short_name"
                      label="客户名"
                      @change="itemChanged"
                      return-object
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.order_date"
                          label="订单日期"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.order_date"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.request_date"
                          label="要求发货日期"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.request_date"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.book_date"
                          label="下单日期"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.book_date"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="blue darken-1" @click="closeEdit">
                取消
              </v-btn>
              <v-btn text color="blue darken-1" @click="saveEdit">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              你确定要删除此订单信息吗？
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                取消
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                确定
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogUpload" max-width="500px">
          <v-form ref="fileupload">
            <v-card>
              <div v-if="currentFile">
                <div>
                  <v-progress-linear
                    v-model="progress"
                    color="light-blue"
                    height="25"
                    reactive
                  >
                    <strong>{{ progress }} %</strong>
                  </v-progress-linear>
                </div>
              </div>

              <v-card-title class="headline">上传文件</v-card-title>
              <v-row dense class="mx-4">
                <v-col cols="6">
                  <v-select
                    v-model="fileType"
                    :items="types"
                    item-text="text"
                    item-value="value"
                    label="文件类型"
                    :rules="[v => !!v || '请选择类型']"
                    prepend-icon="mdi-file-tree"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    accept=".xls,.xlsx,.doc,.docx,.pdf,.stp"
                    required
                    show-size
                    :rules="[v => !!v || '请选择文件']"
                    @change="selectFile"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="blue darken-1" @click="closeUpload">
                  关闭
                </v-btn>
                <v-btn small dark color="blue darken-1" @click="upload">
                  上传
                  <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text
                v-if="fileInfos.length > 0"
                class="pa-2 grey lighten-2"
              >
                <v-list class="py-0">
                  <v-subheader class="grey lighten-2">文件列表</v-subheader>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(file, index) in fileInfos"
                      :key="index"
                    >
                      <v-list-item-avatar>
                        <v-icon
                          title="客户订单"
                          class="orange"
                          dark
                          v-if="file.type == 'SO'"
                        >
                          mdi-alpha-s-circle
                        </v-icon>
                        <v-icon
                          title="采购单"
                          class="amber"
                          dark
                          v-if="file.type == 'PO'"
                        >
                          mdi-alpha-p-circle
                        </v-icon>
                        <v-icon
                          title="图纸资料"
                          class="yellow"
                          dark
                          v-if="file.type == 'Other'"
                        >
                          mdi-alpha-o-circle
                        </v-icon>
                      </v-list-item-avatar>
                      <a
                        :href="uploadsDir + file.type + '/' + file.filename"
                        target="_blank"
                      >
                        {{ file.oriname }}
                      </a>
                      <v-list-item-action>
                        <v-btn
                          icon
                          :href="previewDir + file.type + '/' + file.filename"
                          target="_blank"
                          v-if="showPreview(file.filename)"
                        >
                          <v-icon color="grey lighten-1" title="预览">
                            mdi-eye
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>

              <v-overlay :absolute="true" :value="alert">
                <v-card color="orange darken-4">
                  <v-card-title>失败</v-card-title>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" @click="alert = false">
                      关闭
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
              <v-overlay :absolute="true" :value="success">
                <v-card color="light-green darken-4">
                  <v-card-title>成功</v-card-title>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="success = false">
                      关闭
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog v-model="dialogRemark" max-width="500px">
          <v-form ref="remark">
            <v-card>
              <v-card-title class="headline">添加备注</v-card-title>
              <v-row dense class="mx-4">
                <v-col cols="12">
                  <v-textarea
                    label="备注"
                    auto-grow
                    rows="1"
                    v-model="currentRemark"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="blue darken-1" @click="closeRemark">
                  关闭
                </v-btn>
                <v-btn small dark color="blue darken-1" @click="saveRemark">
                  保存
                  <v-icon right dark>mdi-content-save</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text
                v-if="remarks.length > 0"
                class="pa-2 grey lighten-2"
              >
                <v-list class="py-0">
                  <v-subheader class="grey lighten-2">备注</v-subheader>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(remark, index) in remarks"
                      :key="index"
                    >
                      {{ remark.user_name }}({{ remark.create_date }})：
                      {{ remark.content }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>

              <v-overlay :absolute="true" :value="alert">
                <v-card color="orange darken-4">
                  <v-card-title>失败</v-card-title>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" @click="alert = false">
                      关闭
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
              <v-overlay :absolute="true" :value="success">
                <v-card color="light-green darken-4">
                  <v-card-title>成功</v-card-title>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="success = false">
                      关闭
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-overlay>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-1" color="blue" title="编辑" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        class="mr-1"
        color="orange"
        title="删除"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon class="mr-1" color="green" title="附件" @click="openUpload(item)">
        mdi-cloud-upload
      </v-icon>
      <v-icon
        class="mr-1"
        color="pink lighten-3"
        title="备注"
        @click="openRemark(item)"
      >
        mdi-chat
      </v-icon>
      <v-icon title="详细" color="teal" @click="openDetail(item)">
        mdi-details
      </v-icon>
    </template>
    <template v-slot:[`item.order_status`]="{ item }">
      <v-progress-linear value="60" height="20" color="green">
        {{ item.order_status }}
      </v-progress-linear>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { baseUrl } from "../env";
import authHeader from "../services/auth-header";
import UploadService from "../services/UploadFilesService";
import RemarkService from "../services/AddRemarkService";

export default {
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    search: "",
    dialogEdit: false,
    dialogDelete: false,
    dialogUpload: false,
    dialogRemark: false,
    loading: true,
    headers: [
      { text: "订单号", value: "ordernum", align: "start" },
      { text: "客户订单号", value: "customer_ordernum" },
      { text: "订单日期", value: "order_date" },
      { text: "要求发货日期", value: "request_date" },
      { text: "下单日期", value: "book_date" },
      { text: "客户", value: "short_name" },
      { text: "状态", value: "status" },
      { text: "制单人", value: "create_user" },
      {
        text: "操作",
        value: "actions",
        sortable: false,
        width: "180px",
        align: "center"
      }
    ],
    options: {},
    orders: [],
    selected: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      ordernum: "",
      customer_ordernum: "",
      order_date: "",
      request_date: "",
      book_date: "",
      customer_id: 0,
      short_name: "",
      status: "新建"
    },
    defaultItem: {
      id: 0,
      ordernum: "",
      customer_ordernum: "",
      order_date: "",
      request_date: "",
      book_date: "",
      customer_id: 0,
      short_name: "",
      status: "新建"
    },
    customers: [],
    currentFile: undefined,
    progress: 0,
    alert: false,
    success: false,
    message: "",
    fileInfos: [],
    orderId: 0,
    fileType: null,
    types: [
      { text: "客户订单", value: "SO" },
      { text: "采购单", value: "PO" },
      { text: "图纸资料", value: "Other" }
    ],
    uploadsDir: "",
    previewDir: "",
    remarks: [],
    currentRemark: ""
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "新建订单" : "编辑订单";
    }
  },

  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },

  created() {
    this.getCustomers();
    this.getDataFromApi();
    this.uploadsDir = baseUrl + "/uploads/";
    this.previewDir =
      "https://view.officeapps.live.com/op/view.aspx?src=" + this.uploadsDir;
  },

  methods: {
    itemChanged() {
      this.editedItem.customer_id = this.editedItem.short_name.id;
    },

    getCustomers() {
      fetch(baseUrl + "/api/companys/CUSTOMER", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          return Response.json();
        })
        .then(jsonData => {
          this.customers = jsonData;
        });
    },

    getDataFromApi() {
      this.loading = true;
      fetch(baseUrl + "/api/orders/ALL", {
        method: "get",
        headers: authHeader()
      })
        .then(Response => {
          if (Response.ok) {
            return Response.json();
          } else {
            return Promise.reject({
              status: Response.status,
              statusText: Response.statusText
            });
          }
        })
        .then(jsonData => {
          this.orders = jsonData;
          this.loading = false;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },

    editItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      fetch(baseUrl + "/api/order/" + this.editedItem.id, {
        method: "delete",
        headers: authHeader()
      }).then(function(Response) {
        console.log(Response);
      });
      this.orders.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveEdit() {
      if (this.editedItem.short_name.short_name) {
        this.editedItem.short_name = this.editedItem.short_name.short_name;
      }

      if (this.editedIndex > -1) {
        fetch(baseUrl + "/api/order/" + this.editedItem.id, {
          method: "put",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        Object.assign(this.orders[this.editedIndex], this.editedItem);
      } else {
        fetch(baseUrl + "/api/order", {
          method: "post",
          headers: authHeader(),
          body: JSON.stringify(this.editedItem)
        }).then(function(Response) {
          console.log(Response);
        });

        this.orders.push(this.editedItem);
      }
      this.closeEdit();
    },

    openUpload(item) {
      this.orderId = item.id;
      this.fileType = "";
      if (this.currentFile !== undefined) {
        this.$refs.fileupload.reset();
      }
      UploadService.getFiles(this.orderId).then(response => {
        this.fileInfos = response.data;
      });
      this.dialogUpload = true;
    },

    closeUpload() {
      this.dialogUpload = false;
    },

    upload() {
      if (!this.fileType) {
        this.message = "请选择文件类型！";
        this.alert = true;
        return;
      }

      if (!this.currentFile) {
        this.message = "请选择一个需要上传的文件！";
        this.alert = true;
        return;
      }

      this.message = "";

      UploadService.upload(
        this.currentFile,
        this.fileType,
        this.orderId,
        event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }
      )
        .then(response => {
          this.success = true;
          this.message = response.data.message;
          return UploadService.getFiles(this.orderId);
        })
        .then(files => {
          this.fileInfos = files.data;
        })
        .catch(() => {
          this.progress = 0;
          this.alert = true;
          this.message = "文件上传失败！";
          this.currentFile = undefined;
        });
    },

    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },

    showPreview(fileName) {
      let ext = fileName
        .split(".")
        .pop()
        .toLowerCase();
      if (ext == "xls" || ext == "xlsx" || ext == "doc" || ext == "docx") {
        return true;
      } else {
        return false;
      }
    },

    openRemark(item) {
      this.orderId = item.id;
      this.currentRemark = "";
      RemarkService.getRemarks("ORDER_INFO", this.orderId).then(response => {
        this.remarks = response.data;
      });
      this.dialogRemark = true;
    },

    closeRemark() {
      this.dialogRemark = false;
    },

    saveRemark() {
      if (this.currentRemark == "") {
        this.message = "请输入备注！";
        this.alert = true;
        return;
      }

      this.message = "";

      RemarkService.addRemark("ORDER_INFO", this.orderId, this.currentRemark)
        .then(response => {
          this.success = true;
          this.message = response.data.message;
          return RemarkService.getRemarks("ORDER_INFO", this.orderId);
        })
        .then(remarks => {
          this.remarks = remarks.data;
          this.currentRemark = "";
        })
        .catch(() => {
          this.alert = true;
          this.message = "添加备注失败！";
          this.currentRemark = "";
        });
    },

    openDetail(item) {
      this.orderId = item.id;
      this.$router.push("/order/" + this.orderId);
    }
  }
};
</script>
